@import "../variables";

.header-component {
    position: relative;
    background-color: white;

    max-height: $header-height;
    height: $header-height;

    padding: 0 $sides-section;

    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: space-between;

    position: sticky;
    right: 0;
    left: 0;
    top: 0;

    z-index: 500;

    & a {
        display: inherit;
        color: $text;

        text-decoration: none;
        //margin: $sides-min / 3;
    }

    .img-logo-header{
        max-height: 100%;
        height: 100%;

        display: flex;
        align-items: center;

        img{
            height: 60%;
        }
    }

    .routes-header {
        display: inherit;
        align-items: center;
        align-self: center;
        margin-left: auto;

        gap: 1rem;
        margin-right: 1rem;

        & a {
            display: inherit;
            color: $text;

            text-decoration: none;

            &.active {
                border-bottom: 2px solid $light-white;
                &:hover {
                    border-bottom: 2px solid $light-white;
                }
            }
            &:hover {
                padding-top: 2px;
                border-bottom: 2px solid rgba(225, 225, 225, 1);
            }
        }
    }

    .mini-menu-routes-header {
        display: inherit;
        overflow-x: hidden;
        justify-content: flex-end;
        & > div {
            display: inherit;
            margin-left: $sides-min / 2;

            background-color: white;

            gap: .5rem; 
            padding: 0;

            .shopping-icon {
                position: relative;
                &:hover {
                    overflow-x: hidden;

                    color: $text;
                    background-color: white;
                    cursor: pointer;

                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;

                    i {
                        transform: translateX(100%);
                        -webkit-transform: translateX(100%);
                        -moz-transform: translateX(100%);
                        -ms-transform: translateX(100%);
                        -o-transform: translateX(100%);
                    }
                    p{
                        color: $text;
                        display: inline;

                        
                    }
                }
                i {
                    transition: transform $transition2;
                    -webkit-transition: transform $transition2;
                    -moz-transition: transform $transition2;
                    -ms-transition: transform $transition2;
                    -o-transition: transform $transition2;
}
                p {
                    display: none;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    -o-transform: translate(-50%, -50%);
}
            }

            .active-shopping > i {
                background-color: white;
                color: white;

                &:hover {
                    background-color: white;
                    color: white;
                }
            }

            & > a > i {
                color: $dark;
                background-color: white;
                cursor: pointer;

                padding: 0.25rem;

                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;

                &:hover {
                    background-color: white;
                    color: $dark;
                    &::before {
                        /*transition: transform $transition;
                    transform: scale(1.025);
                    -webkit-transform: scale(1.025);
                    -moz-transform: scale(1.025);
                    -ms-transform: scale(1.025);
                    -o-transform: scale(1.025);
                    -webkit-transition: transform $transition;
                    -moz-transition: transform $transition;
                    -ms-transition: transform $transition;
                    -o-transition: transform $transition;*/
                    }
                }
                &::before {
                    font-size: 1.5rem;
                    font-weight: 400 !important;
                }
            }
            border-radius: 50rem;
            -webkit-border-radius: 50rem;
            -moz-border-radius: 50rem;
            -ms-border-radius: 50rem;
            -o-border-radius: 50rem;
        }
    }

    @media screen and (max-width: $mobile) {
        flex-flow: wrap row;
        align-items: center;
        justify-content: center;
        max-height: unset;
        height: unset;

        padding-bottom: 1rem;

        .img-logo-header {
            order: 1;
            flex: 1 1 calc(50% - 1rem);

            max-height: 4rem;
            height: 4rem;
        }
        .routes-header {
            order: 3;
            flex: 1 1 100%;
            justify-content: center;
        }
        .mini-menu-routes-header {
            order: 2;
            flex: 1 1 calc(50% - 0.5rem);
            margin: 0;
            //max-width: 82px;
        }
    }

    @media screen and (max-width: 400px) {
        & a {
            font-size: 0.9rem;
        }
        padding-bottom: 0.5rem;

        .img-logo-header {
            flex: 1 1 calc(50% - 3rem);
        }
        .mini-menu-routes-header {
            flex: 1 1 calc(50% - 3.5rem);
            margin: 0;
        }
    }
}

.shopping-cart-component {
    position: absolute;
    display: flex;
    flex-flow: nowrap column;
    align-items: center;
    justify-content: flex-start;
    padding: $sides-min / 2;
    width: $shopping-cart-width;

    background-color: $light-white;

    border-radius: $radius / 2;
    -webkit-border-radius: $radius / 2;
    -moz-border-radius: $radius / 2;
    -ms-border-radius: $radius / 2;
    -o-border-radius: $radius / 2;

    z-index: 999;
}
