@import "../variables";

.asociated-images {
    width: 100%;

    box-sizing: border-box;

    padding: 1rem;

    background-color: rgba($color: $gray, $alpha: 0.05);

    .swiper-wrapper {
        height: 7rem;
        max-height: 7rem;
        min-height: 7rem;

    }

    .slide-image-asoc {

        img {
            border-radius: $radius;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .swiper-pagination {
        bottom: 0;

        .swiper-pagination-bullet-active {
            background-color: white;
        }
    }
}

.page-product {
    .course-content {
        .provider {
            padding: 1rem 1rem 0 1rem;

            &>div {
                position: relative;
                padding: 1rem;

                border: 1px solid rgba($color: $green-light, $alpha: 0.5);
                border-radius: $radius / 2;

                display: flex;
                flex-flow: nowrap column;
                align-items: flex-start;
                justify-content: center;

                background-color: rgba($color: $gray, $alpha: 0.03);

                gap: .5rem;

                span {
                    margin-bottom: 0;
                    font-size: .8rem;
                }

                &>div {
                    display: none;
                    flex-flow: nowrap column;
                    align-items: flex-start;
                    justify-content: center;

                    &.show {
                        display: flex;
                    }

                    small {
                        font-size: .7rem;
                    }

                    b {
                        color: rgba($color: #000000, $alpha: 0.8);
                    }
                }

                &>i {
                    position: absolute;
                    top: .5rem;
                    right: .5rem;

                    cursor: pointer;
                    color: $green-light;
                }
            }


        }
    }

    .course-info {
        .name-with-sc {
            display: flex;
            flex-flow: wrap row;
            align-items: center;
            justify-content: space-between;

            margin-bottom: .5rem;

            span.badge {
                margin: 0;
                font-size: .7rem;
                background-color: white;
                color: $green-light;
                border: 1px solid $green-light;
                border-radius: $radius;
                padding: .25rem .5rem;
            }
        }
    }

    .btn-green {
        background-color: $green-light;
        border: 1px solid $green-light;

        &:hover {
            border: 1px solid $green-light;
            color: $green-light;
        }
    }

    i {
        color: $green-light !important;
    }

    .course-page {
        position: relative;
        .btns-aux {
            position: absolute;
            top: 1rem;
            right: 1rem;
            display: flex;
            flex-flow: nowrap row;
            align-items: center;
            justify-content: center;

            &>div{
                display: flex;
                align-items: center;
                justify-content: center;

                gap: .5rem;

                border: 1px solid $green-light;
                padding: .25rem .5rem;
                border-radius: $radius;
                background-color: rgba($color: white, $alpha: 1);
                cursor: pointer;
                small{
                    display: none;
                    color: $green-light;

                    white-space: nowrap;
                    overflow: hidden;
                }

                &:hover{
                    background-color: $green-light;
                    i{
                        color: white !important;
                    }
                    small{
                        display: block;
                        color: white ;
                        max-width: max-content;
                    }
                }
            }
        }
    }
}