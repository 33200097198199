@import "../variables";

.magazzine-page {
    background-color: rgba($color: #000000, $alpha: 0.07);
}

.magazzine-blue-bg {
    display: flex;
    flex-flow: nowrap column;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding-bottom: $sides-max + 4rem;

    background-color: $blue-gray-dark;

    &>* {
        color: white;
        display: block;
        width: 100%;
        text-align: center;
    }

    &>h1 {
        font-weight: 300;
    }

    &>h5 {
        margin-top: 1rem;
        font-weight: 300;
    }
}

.magazzine-plans {
    display: flex;
    flex-flow: wrap row;
    align-items: stretch;
    justify-content: space-between;

    gap: 3rem;

    margin-top: -2rem;
    padding-top: 0 !important;
    padding-bottom: 1rem;

    padding-left: 13%;
    padding-right: 13%;

    margin-bottom: 2rem;

    @media screen and (max-width: $tablet) {
        padding-left: $sides;
        padding-right: $sides;
    }

    & .card-magazzine-plan:nth-child(even) {
        background-color: $light-blue;
    }

    & .card-magazzine-plan:nth-child(2n+1) {
        background-color: $blue-gray;
    }
}

.card-magazzine-plan {
    flex: 1 1 calc(33% - 2rem);
    width: calc(33% - 2rem);

    display: flex;
    flex-flow: nowrap column;
    align-items: flex-start;
    justify-content: flex-start;

    box-shadow: $dark-shadow;

    &.noborder {
        border-radius: 0 0 $radius $radius !important;
        -webkit-border-radius: 0 0 $radius $radius !important;
        -moz-border-radius: 0 0 $radius $radius !important;
        -ms-border-radius: 0 0 $radius $radius !important;
        -o-border-radius: 0 0 $radius $radius !important;
    }

    border-radius: $radius $radius;
    -webkit-border-radius: $radius $radius;
    -moz-border-radius: $radius $radius;
    -ms-border-radius: $radius $radius;
    -o-border-radius: $radius $radius;

    padding: 0rem 2rem 2rem 2rem;

    position: relative;

    &>* {
        color: white;
    }

    &>h4 {
        font-weight: 600;

        display: block;
        width: 100%;
        text-align: center;
    }

    &>p {
        font-size: 0.9rem;
        font-weight: 200;
        margin-bottom: 2rem;

        display: block;
        width: 100%;
        text-align: center;
    }

    &>h2 {
        margin-bottom: 0.5rem;

        display: block;
        width: 100%;
        text-align: center;
    }

    &>button {
        color: $light-blue;
        border-radius: 15rem;
        font-size: 1rem;
        display: flex;
        align-items: center;

        padding: .25rem 1.25rem;

        margin: 0 auto;

        margin-bottom: 2rem;

        b {
            padding-top: 2px;
        }
    }

    &>hr {
        display: block;
        width: 100%;
        height: 2px;
        border-bottom: 2px solid;
    }

    &:nth-child(even) {
        &>hr {
            border-color: $blue-gray;
        }
    }

    &:nth-child(2n+1) {
        &>hr {
            border-color: $light-blue;
        }
    }

    &>.title-plan {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &>.icon-sup-magazzine-plan {
        width: 7rem;
        height: 7rem;

        margin: -3.5rem auto .5rem auto;
    }

    &>.winning-magazzine-plans-container {
        display: flex;
        flex-flow: nowrap column;
        align-items: center;
        justify-content: center;

        padding-top: 2rem;

        padding-right: 1rem;

        box-sizing: border-box;
        width: 100%;

        .winning-magazzine-plan {
            display: inherit;
            flex-flow: nowrap row;
            align-items: center;
            justify-content: flex-start;

            width: 100%;

            margin-bottom: 0.5rem;

            &>i {
                margin-right: 0.5rem;
                color: white;

                &::before {
                    font-weight: 800 !important;
                }
            }

            &>small {
                font-size: .8rem;
                font-weight: 200;
            }
        }
    }

    .habs-icons {
        display: flex;
        flex-flow: nowrap row-reverse;
        align-items: center;
        justify-content: center;

        position: absolute;

        top: 1rem;
        right: 1rem;
        gap: .5rem;

        i {
            color: $blue;

            z-index: 10;
        }

        /*
        background-color: $blue;
        border-radius: $radius;

        padding: .25rem;

        transform: translateY(-25%);

        i{
            color: white;
        }
        */
    }

    @media screen and (max-width: $mobile) {
        flex: 1 1 calc(50% - .5rem);
        width: calc(50% - .5rem);

        &:nth-child(3) {
            margin-top: 1rem;
        }
    }

    @media screen and (max-width: 500px) {
        flex: 1 1 100%;
        width: 100%;

        margin-right: 0;
        margin-left: 0;

        margin-top: 1rem;
    }

    @media screen and (max-width: $mini-mobile) {}
}