@import "variables";
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;

    font-family: "Rubik", sans-serif !important;
}

body {
    min-width: fit-content !important;
}

.ReactCollapse--collapse {
    transition: height 500ms;
}

section.section{
    padding: 2rem $sides-section;
}

.sides {
    padding-right: $sides;
    padding-left: $sides;

    padding-top: $sides;
    padding-bottom: $sides;
    box-sizing: border-box;

    &#{"-min-x"} {
        padding-right: $sides-min;
        padding-left: $sides-min;
        box-sizing: border-box;
    }
    &#{"-max-x"} {
        padding-right: $sides-max;
        padding-left: $sides-max;
        box-sizing: border-box;
    }
    &#{"-lg-x"} {
        padding-right: $sides-lg;
        padding-left: $sides-lg;
        box-sizing: border-box;
    }
    &#{"-section-x"} {
        padding-right: 7%;
        padding-left: 7%;
        box-sizing: border-box;
    }

    &#{"-min-y"} {
        padding-top: $sides-min;
        padding-bottom: $sides-min;
        box-sizing: border-box;
    }
    &#{"-max-y"} {
        padding-top: $sides-max;
        padding-bottom: $sides-max;
        box-sizing: border-box;
    }
    &#{"-lg-y"} {
        padding-top: $sides-lg;
        padding-bottom: $sides-lg;
        box-sizing: border-box;
    }
    &#{"-section-y"} {
        padding-top: 7%;
        padding-bottom: 7%;
        box-sizing: border-box;
    }
}

.contrast {
    background-color: $light-white;
}

.form {
    display: flex;
    flex-flow: nowrap column;
    align-items: center;
    justify-content: center;

    background-color: $light-white;

    padding: $sides-max 0;

    min-height: 80vh;

    @media screen and (max-width: $mobile) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.form-content {
    display: flex;
    flex-flow: nowrap column;
    align-items: center;
    justify-content: center;

    max-width: 50%;
    width: 35%;

    background-color: white;
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;

    box-sizing: border-box;

    padding: $sides;

    & .form-header {
        width: 100%;
        margin-bottom: 1rem;
        padding-bottom: 1rem;

        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        * {
            display: block;
            width: 100%;
            text-align: start;
            //color: $text;
        }

        & > h4 {
            font-weight: 600;
            margin-bottom: 0.15rem;
        }
        & > span {
            font-weight: 400;
            font-size: 0.9rem;

            small {
                color: $text;

                display: inline-flex;
                flex-flow: nowrap row;
                align-items: center;
                justify-content: flex-start;

                button {
                    font-size: 0.7rem;
                    width: auto;
                    display: inline;
                    padding: 0.1rem 0.25rem;

                    margin-left: 0.25rem;

                    color: $text;
                    background-color: white;
                    border: 1px solid $text;
                    border-radius: 5rem;
                    -webkit-border-radius: 5rem;
                    -moz-border-radius: 5rem;
                    -ms-border-radius: 5rem;
                    -o-border-radius: 5rem;
                }
            }
        }
    }

    & .form-body {
        width: 100%;

        display: grid;
        align-items: end;
        grid-auto-flow: row dense;

        &.border-top {
            padding-top: 1rem;
            margin-top: 1rem;
            border-top: 1px solid $gray;
        }

        & .col-1 {
            grid-column-start: 1;
        }
        & .col-2 {
            grid-column-start: 2;
        }
        & .col-3 {
            grid-column-start: 3;
        }

        &.q- {
            &#{"1"} {
                grid-template-columns: repeat(1, minmax(0, 1fr));
                column-gap: 1rem;
                row-gap: 0.5rem;
            }
            &#{"2"} {
                grid-template-columns: repeat(2, minmax(0, 1fr));
                column-gap: 1rem;
                row-gap: 0.5rem;
            }
            &#{"3"} {
                grid-template-columns: repeat(3, 1fr);
                gap: 1rem;
            }
            &#{"4"} {
                grid-template-columns: repeat(4, minmax(0, 1fr));
                gap: 1rem;
            }
        }

        @media screen and (max-width: $tablet) {
            &.q- {
                &#{"2"} {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                    gap: 1rem;
                    row-gap: 0.25rem;

                    & .form-input {
                        grid-column-start: 1;
                        grid-column-end: 3;
                    }

                    & .col-2 {
                        grid-column-start: 1;
                        grid-column-end: 3;
                    }
                }
                &#{"3"} {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    gap: 1rem;
                }
                &#{"4"} {
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                    gap: 1rem;
                }
            }
        }
    }

    & .form-input {
        display: flex;
        flex-flow: nowrap column;
        align-items: flex-start;

        font-weight: 200;
        font-size: 1rem;

        width: 100%;

        label {
            margin-bottom: 0.25rem;

            width: 100%;
            color: $text;
            font-size: 0.9rem;

            small {
                &[color="red"] {
                    color: red;
                }
            }
        }
        .form-input-element {
            width: 100%;
            display: inherit;
            flex-flow: nowrap row;
            align-items: center;
            justify-content: center;

            position: relative;

            input,
            select {
                flex: 1 1 100%;
                width: 100%;
                height: 1.5rem;
                max-height: 1.5rem;
                min-height: 1.5rem;

                padding: 0 0.5rem;

                box-sizing: border-box;

                border: 1px solid rgba(0, 0, 0, 0.3);

                border-radius: calc($radius / 2);
                -webkit-border-radius: calc($radius / 2);
                -moz-border-radius: calc($radius / 2);
                -ms-border-radius: calc($radius / 2);
                -o-border-radius: calc($radius / 2);

                outline: none;

                &:hover,
                &:focus {
                    outline: 1px solid rgba(0, 0, 0, 0.3);
                }

                &[innerType="password"] {
                    & + div.form-input-password-icon {
                        width: 1.25rem;
                        height: 1.25rem;
                        max-height: 1.25rem;
                        min-height: 1.25rem;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        position: absolute;
                        right: 0.25rem;
                        top: 50%;

                        cursor: pointer;

                        &:hover {
                            color: $blue;
                        }
                        transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                    }
                }
            }
        }

        .autocomplete-root {
            width: 100%;
            display: flex;
            flex-flow: nowrap column;
            align-items: stretch;
            justify-content: flex-start;

            & > div.autocomplete-dropdown-container {
                display: inherit;
                flex-flow: nowrap column;
                align-items: stretch;
                justify-content: flex-start;

                max-width: 100%;

                span {
                    display: block;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    word-wrap: break-word;

                    border-bottom: 1px solid $light-white;
                    padding: 0.25rem 0.5rem;

                    cursor: pointer;

                    &:hover {
                        background-color: $light-white;
                    }
                }
            }
        }

        margin-bottom: 0.75rem;
    }
    & > a {
        width: 100%;
        display: block;
        text-align: left;
        color: $blue;
        font-size: 0.9rem;
        margin: 0.25rem 0;
    }
    & .form-footer {
        width: 100%;
        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        justify-content: flex-end;

        padding-top: $sides-min;

        &.space-between {
            justify-content: space-between;
        }

        & > * {
            margin-right: 0.5rem;

            &:last-child {
                margin-right: 0;
            }
        }

        &.border-top {
            margin-top: 1rem;
            border-top: 1px solid rgba(0, 0, 0, 0.3);
        }

        &.centered {
            justify-content: center;
        }
        &.column {
            flex-flow: nowrap column;
            & > * {
                margin-bottom: 0.5rem;
            }
        }
    }

    &.expanded {
        max-width: 70%;
        width: 60%;

        @media screen and (max-width: $tablet) {
            width: 70%;
        }
        @media screen and (max-width: $mobile) {
            width: 100%;
            max-width: 100%;

            min-height: 80vh;
        }
    }

    @media screen and (max-width: $tablet) {
        width: 50%;
    }
    @media screen and (max-width: $mobile) {
        width: 100%;
        max-width: 100%;

        min-height: 80vh;
    }
}

/*      IMAGE BG        */
.image-bg {
    //position: relative;

    width: 100%;
    height: 100%;
    min-height: calc(100vh - $header-height);

    z-index: -1 !important;

    & img.bg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        z-index: -5 !important;

        width: 100%;
        height: 100%;
        object-fit: cover;

        &+*{
            
        }
    }
}

//@at-root

.m {
    &#{"t-1"} {
        margin-top: 1rem;
    }
    &#{"t-2"} {
        margin-top: 2rem;
    }
    &#{"t-3"} {
        margin-top: 3rem;
    }

    &#{"b-1"} {
        margin-bottom: 1rem;
    }
    &#{"b-2"} {
        margin-bottom: 2rem;
    }
    &#{"b-3"} {
        margin-bottom: 3rem;
    }
}

/*      CHECKBOX        */
input {
    &[type="checkbox"] {
        appearance: none;
        height: 1rem;
        width: 1rem;
        outline: 2px solid $blue !important;
        cursor: pointer;
        &:checked {
            padding: 0;
            margin: 0;
            &::after {
                content: "";
                width: 0.65rem;
                height: 0.65rem;
                position: absolute;
                top: 0.17rem;
                left: 0.17rem;

                margin: 0;
                padding: 0;

                background-color: $blue;

                border-radius: 2px;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                -ms-border-radius: 2px;
                -o-border-radius: 2px;
            }
            padding: 2px;
            position: relative;
        }
        border-radius: 2px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        -ms-border-radius: 2px;
        -o-border-radius: 2px;
        transform: scale(.9);
        -webkit-transform: scale(.9);
        -moz-transform: scale(.9);
        -ms-transform: scale(.9);
        -o-transform: scale(.9);
}
}

button{
    cursor: pointer !important;
}

//PHONE INPUT'
.PhoneInput{
    
}

/*  
SNACKBAR
*/
.SnackbarContainer-top{
    top: $header-height !important;
}

/**/

i{
    &[color="blue"]{
        color: $blue;
    }
}




.swiper-wrapper {
    align-items: stretch;

    &>div {
        height: unset !important;
    }
}