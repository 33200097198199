@import "../variables";

.title-component{
    display: flex;
    flex-flow: nowrap column;
    align-items: flex-start;
    justify-content: center;

    margin-bottom: 2rem;

    &>span{
        font-size: 1.25rem;
        font-weight: 400;
        color: $dark;

        display: block;
        margin-bottom: .25rem;
    }

    div{
        display: block;
        height: 3px;
        width: 5rem;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;

        background-color: $blue;
}
}