@import "../variables";

$radius: calc($radius * 2);
$light-blue: #455b76;
.card-component {
    display: flex;
    flex-flow: nowrap column;
    align-items: center;
    justify-content: stretch;

    cursor: pointer;

    border: none;

    &:hover {
        //box-shadow: 0 0 10px 5px rgba(0,0,0,.053);
        //border: 1px solid rgba(0, 0, 0, 0.1);
    }

    &>img, &>*>img {
        width: 100%;
        max-width: 100%;

        max-height: 10rem;
        min-height: 10rem;
        object-fit: cover;

        border-radius: $radius $radius 0 0;
        -webkit-border-radius: $radius $radius 0 0;
        -moz-border-radius: $radius $radius 0 0;
        -ms-border-radius: $radius $radius 0 0;
        -o-border-radius: $radius $radius 0 0;

        //

        border-radius: $radius;
        z-index: 15;
    }

    &>div {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-flow: nowrap column;
        align-items: center;
        justify-content: flex-start;

        padding: 1.5rem 0.75rem 0.75rem 0.75rem;
        margin-top: calc(calc($radius * (-1)) / 1.25);
        background-color: $light-blue !important;

        height: 100%;

        color: white;

        z-index: 10;

        &>* {
            line-height: 1.3rem;
        }

        &>span {
            width: 100%;
            display: block;
            text-align: center;
            margin-bottom: 0.5rem;
            padding: 25% 0 25% 55% ;
            @media screen and (max-width: 350px) {
                padding: 1rem 0;
            }

            font-weight: 500;
        }

        &>p {
            font-weight: 300;
            text-align: center;

            margin-top: auto;

            &.card-price {
                margin-top: auto !important;
                width: 100%;
                display: block;
                text-align: end;
                font-weight: 400;
            }
        }

        border-radius: $radius $radius;
        -webkit-border-radius: $radius $radius;
        -moz-border-radius: $radius $radius;
        -ms-border-radius: $radius $radius;
        -o-border-radius: $radius $radius;
    }

    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
}

.cards-manager-component {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &>* {
        flex: 0 0 25%;
    }

    gap: 3rem;

    @media screen and (max-width: $tablet) {
        &>* {
            flex: 0 0 33%;
        }
    }

    @media screen and (max-width: $mobile) {
        &>* {
            flex: 0 0 50%;
        }
    }

    @media screen and (max-width: $mini-mobile) {
        &>* {
            flex: 0 0 100%;
        }
    }
}

picture.img {
    $radius : 2rem;

    display: block;
    position: relative;

    z-index: 100;

    width: 65%;

    margin-bottom: -60%;
    margin-left: -50%;
    //transform: translateY(60%) translateX(-70%);

    @media screen and (max-width: 350px){
        //transform: unset !important;
        margin-bottom: 0;
        margin-left: 0;

        width: 100% !important;
        &>img{
            width: 100%;

            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }

        div.hidde{
            display: none;
        }
    }

    &>img {
        min-height: 15rem;
        height: 15rem;

        border-radius: $radius;

        object-position: 90% 50%;
    }

    div.hidde {
        position: absolute;

        bottom: 0;
        right: 0;

        background-color: $light-blue;

        width: 15%;
        height: 60%;

        border-radius: calc($radius / 2) 0 0 0;

        .hidde-sup-top-corner {
            display: block;
            width: calc(calc($radius / 2) - 1px);
            height: calc($radius / 2); 
            margin-left: auto;
            margin-top: calc(calc(calc($radius / 2) * -1) + 1px);

            top: 0;
            right: 0;

            *{
                fill: $light-blue !important;
            }
        }

        .hidde-left-bott-corner {
            display: block;

            position: absolute;

            left: calc(calc(calc($radius / 1) * -1) + 2px);
            bottom: calc(calc($radius / 1) - 2px);

            width: calc(calc($radius / 1) - 1px);
            height: calc($radius / 1);
            margin-right: auto;
            margin-bottom: calc(calc(calc($radius / 1) * -1) + 0px);

            *{
                fill: $light-blue !important;
            }
        }
    }
}