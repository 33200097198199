@import "../variables";

.button-component {
    display: block;
    padding: 0.5rem 1rem;

    color: $blue;
    background-color: white;
    border: 1px solid $blue;

    border-radius: $radius / 2;
    -webkit-border-radius: $radius / 2;
    -moz-border-radius: $radius / 2;
    -ms-border-radius: $radius / 2;
    -o-border-radius: $radius / 2;

    cursor: pointer;

    &:hover {
        background-color: $blue;
        color: white;
    }

    &:disabled{
        background-color: rgba(0,0,0,.05);
        border-color: $blue-disabled;
        color: $blue;

        cursor: default;
        &:hover{
            background-color: rgba(0,0,0,.05);
        border-color: $blue-disabled;
        color: $blue;
        }
    }
}

button[color="solid"] {
    background-color: $blue;
    color: white;

    &:hover {
        color: $blue;
        background-color: white;
        border: 1px solid $blue;
    }
    &:disabled{
        background-color: $blue-disabled;
        color: white;
        cursor: default;

        &:hover{
            background-color: $blue-disabled;
            color: white;
            border-color: $blue-disabled;
        }
    }
}
