@import "../variables";

.shopping-page {
    .shopping-cart-container-row {
        width: 100%;
        height: 100%;

        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: stretch;

        column-gap: 1rem;
        row-gap: 0.75rem;

        & .shopping-cart-card {
            //background-color: $light-white;
            border: 1px solid $light-white;
            //box-shadow: $dark-shadow;
            justify-content: space-between;

            &.subs{
                cursor: pointer !important;
            }
        }

        @media screen and (max-width: $tablet) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    .ReactCollapse--collapse {
        margin-top: 0rem;
        width: 100%;
    }

    .price-and-policy {
        div {
            display: flex;
            flex-flow: nowrap row;
            align-items: center;
            &:first-child {
                label {
                    margin-left: 0.25rem;
                    color: $text;
                    font-size: 0.9rem;
                }
                a {
                    color: $blue;
                    text-decoration: none;
                    font-weight: 500;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        & > span {
            font-size: 0.9rem;
        }
    }

    .collapse-map-specified-location {
        width: 100%;

        &[aria-hidden="false"] {
            height: 40vh !important;

            & .collapse-map-specified-location-content {
                height: 40vh !important;

                display: flex;
                flex-flow: nowrap column;
            }
        }

        &#{"-content"} {
            width: 100%;
        }
    }
}

.shopping-cart-component {
    //background-color: white;

    display: flex;
    flex-flow: nowrap column;
    align-items: stretch;
    justify-content: flex-start;

    z-index: 1000;

    & > * {
        margin-bottom: 0.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    box-shadow: $dark-shadow;
}
.shopping-cart-card {
    background-color: white;

    border-radius: calc($radius / 2);
    -webkit-border-radius: calc($radius / 2);
    -moz-border-radius: calc($radius / 2);
    -ms-border-radius: calc($radius / 2);
    -o-border-radius: calc($radius / 2);

    display: flex;
    flex-flow: nowrap column;
    align-items: stretch;
    justify-content: center;

    padding: 0.5rem;

    &.disabled {
        background-color: $light-white !important;

        .button-remove {
            opacity: 0.7;
        }

        .button-change {
            opacity: 0.7;
        }
    }

    & > .row-1 {
        width: 100%;
        margin-bottom: 0.5rem;

        display: inherit;
        flex-flow: nowrap row;
        align-items: flex-start;
        justify-content: space-between;

        & > div {
            span {
                color: $text;
                font-size: 0.9rem;
                font-weight: 300;

                text-transform: unset !important;
            }

            p {
                color: $text;
                font-size: 0.8rem;
                font-weight: 200;
            }
        }

        .button-remove {
            background-color: white;
            border-color: $red;
            color: $red;

            padding: 0.1rem 0.5rem;
            font-size: 0.7rem;

            margin-bottom: 0.25rem;
        }

        .button-change {
            background-color: white;
            border-color: $gray;
            color: $gray;

            padding: 0.1rem 0.5rem;
            font-size: 0.7rem;
        }

        button {
            width: 100%;
            border-radius: 50rem;
            -webkit-border-radius: 50rem;
            -moz-border-radius: 50rem;
            -ms-border-radius: 50rem;
            -o-border-radius: 50rem;
        }
    }
    & > .row-2 {
        width: 100%;

        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        justify-content: space-between;

        span {
            display: inline-flex;
            flex-flow: nowrap row;
            align-items: center;
            justify-content: center;

            color: $text;
            font-weight: 200;
            font-size: 0.9rem;

            input {
                max-width: 3ch;
                border: none;
                margin-left: 0.25rem;

                font-weight: 600;
            }

            b {
                margin-left: 0.25rem;
                font-weight: 600;
            }
        }
    }
}

.qr-component-payment {
    .qr-contents {
        width: 100%;
        display: flex;
        flex-flow: nowrap row;
        align-items: stretch;
        justify-content: space-between;

        & > div {
            display: inherit;
            flex-flow: nowrap column;
            align-items: stretch;
            justify-content: flex-start;

            &:first-child {
                width: 100%;
                padding-right: 2rem;
                & > span {
                    font-size: 1rem;
                    font-weight: 300;

                    margin-bottom: 0.75rem;

                    &.end {
                        width: 100%;
                        display: block;
                        text-align: end;

                        padding: 0.5rem 0;
                    }
                }

                & > .shopping-cart-card {
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    margin-bottom: 0.25rem;
                }
            }

            &:last-child {
                align-items: center !important;
                div {
                    width: auto;
                    border-radius: 50rem;
                    -webkit-border-radius: 50rem;
                    -moz-border-radius: 50rem;
                    -ms-border-radius: 50rem;
                    -o-border-radius: 50rem;

                    padding: 0.25rem 0.5rem;
                    margin: 0.75rem 0;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    border: 1px solid rgba(0, 0, 0, 0.1);

                    span {
                        font-size: 0.9rem;
                    }

                    i {
                        &.bi-circle-fill {
                            margin-right: 0.25rem;
                        }
                        &.bi-arrow-clockwise {
                            margin-left: 0.25rem;
                            cursor: pointer;

                            width: 1.25rem;
                            height: 1.25rem;
                            min-width: 1.25rem;
                            min-height: 1.25rem;
                            max-width: 1.25rem;
                            max-height: 1.25rem;

                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &:hover {
                                background-color: $blue;
                                color: white;
                                border-radius: 50%;
                                -webkit-border-radius: 50%;
                                -moz-border-radius: 50%;
                                -ms-border-radius: 50%;
                                -o-border-radius: 50%;
                            }
                        }

                        &.yellow {
                            color: rgb(227, 227, 0);
                        }
                        &.green {
                            color: green;
                        }
                        &.red {
                            color: red;
                        }
                        &.gray{
                            color: $gray;
                        }
                    }

                    &.download-qr {
                        cursor: pointer;
                        margin-top: 0;
                        margin-bottom: 1rem;
                        i {
                            margin-right: 0.25rem;
                        }
                        &:hover {
                            background-color: $blue;

                            span {
                                color: white;
                            }
                            i {
                                color: white;
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $tablet) {
            flex-flow: nowrap column-reverse;
            .download-qr {
                margin-bottom: 2rem;
            }
        }
    }
}

.shopping-cart-container-row {
    .state-subscription {
        &[color="yellow"] {
            color: rgb(227, 227, 0) !important;
            border-color: rgb(227, 227, 0) !important;
        }
        &[color="green"] {
            color: green !important;
            border-color: green !important;
        }
        &[color="red"] {
            color: red !important;
            border-color: red !important;
        }
        border-radius: 50rem;
        -webkit-border-radius: 50rem;
        -moz-border-radius: 50rem;
        -ms-border-radius: 50rem;
        -o-border-radius: 50rem;
    }
}


.shopping-cart-void{
    width: 100%;

    display: flex;
    flex-flow: nowrap column;
    align-items: stretch;

    label{
        margin: 1rem 0;

        display: block;
        text-align: center;
    }
}