@import "https://development.nuevaeconomia.com.bo/css/components.css";
@import "../variables";

.footer-component{
    box-sizing: border-box;
    background-color: $dark;
}

#footer-component{
    padding-right: $sides-section;
    padding-left: $sides-section;
}