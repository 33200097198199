@import "../variables";

/*
    COURSE 
*/


.card-description {
    display: block;
    text-align: left;
    width: 100%;
}

.page-course-container {
    background-color: rgba(0, 0, 0, .1);

    display: flex;
    flex-flow: nowrap column;
    align-items: center;

    img {
        width: 100%;
        max-width: 100%;
    }

    .course-page {
        display: flex;
        flex-flow: nowrap column;
        align-items: center;
        justify-content: flex-end;

        width: 60%;
        box-sizing: border-box;

        background-color: white;

        @media screen and (max-width: $tablet) {
            width: 80%;
        }

        @media screen and (max-width: $mini) {
            width: 100%;
        }
    }
}

.course-content {
    width: 100%;

    display: inherit;
    //flex-flow: nowrap row;
    align-items: stretch;
    justify-content: center;
    background-color: white;

    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;

    //padding: $sides;

    span {
        font-weight: 200;
        font-size: 1rem;
        text-transform: uppercase;

        display: block;
        margin-bottom: 0.75rem;
    }

    &>.course-info {
        padding: $sides;

        &>h2 {
            display: block;

            font-weight: 600;
            font-size: 1.25rem;
            margin-bottom: 0.5rem;
        }

        &>p {
            font-weight: 400;
            font-size: 0.9rem;

        }
    }

    &>.course-winning {
        margin-left: 2rem;
        padding-left: 2rem;
        border-left: 1px solid rgba(0, 0, 0, .15);

        & span {
            padding-top: $sides;
            padding-right: $sides;
        }
    }

    & .winning-course-list {
        list-style: none !important;
        display: block;
        margin-bottom: 1rem;

        padding-right: $sides;

        &>li.winning-course {
            list-style: none;
            display: flex;
            flex-flow: nowrap row;
            align-items: flex-start;

            margin-bottom: 0.5rem;

            i {
                display: inline-block;
                margin-right: 0.15rem;
                color: $blue;
                font-size: 1rem;

                &::before {
                    font-weight: 600 !important;
                }
            }

            p {
                display: inline;
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }

    & button {
        margin-right: $sides;
        margin-bottom: $sides;
    }

    flex-flow: nowrap column;

    .course-winning {
        margin-left: 0 !important;
        padding: $sides !important;
        padding-top: 0 !important;

        span {
            padding-top: 0 !important;
        }

        button {
            margin: 0 auto 0 auto !important;
        }
    }
}


.course-page {
    position: relative;

    .controllers {
        position: absolute;
        top: 1rem;
        left: 1rem;

        display: flex;
        flex-flow: nowrap column;
        align-items: stretch;
        justify-content: flex-start;
        gap: .5rem;

        small {
            padding: .25rem .75rem;
            border-radius: $radius;

            &[color="light"] {
                background-color: white;
            }

            &[color="danger"] {
                background-color: $red;
                color: white;
            }
        }
    }
}

.items-not-found-message {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 5rem;
    ;
}