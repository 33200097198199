//COLORS

//$light-blue: #95C0EF;

$blue: #7099D1;
$blue-disabled: rgba(112, 153, 209, .5);

$dark: #343A40;

$light-white: #f4f4f4;

$gray: rgba(0,0,0,.3);

$blue-gray-dark: #132133;
$blue-gray: #35475f;
$light-blue: #428ecc;

$text: #606060;

$red: rgba(255,0,0,.5);

$green-light: #bdb246;

//SIZES
$header-height: 4rem;
$shopping-cart-width: 15rem;

$mini-mobile: 350px;
$mobile: 768px;
$mini: 400px;
$tablet: 992px;


//SIZING
$sides-min: 1rem;
$sides: 2rem;
$sides-max: 3rem;
$sides-lg: 4rem;
$sides-section: 7%;


//RADIUS
$radius: 10px;

//TIMEs
$transition: .1s ease;
$transition2: .5s ease;

//SHADOW
$dark-shadow: 0 0px 15px rgba(0,0,0,.1);