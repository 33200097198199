@import "../variables";

.policy-page {
    .content {
        padding: $sides $sides-section;

        * {
            display: block;
            width: 100%;
            text-align: justify;
        }

        h2 {
            text-align: center !important;
            margin-bottom: 2rem;
        }

        span {
            margin-bottom: 1rem;
            margin-top: 2.5rem;
        }

        p {
            margin-bottom: .5rem;
        }

        ol {
            margin-bottom: 1.25rem;
            margin-top: 1.25rem;
            list-style: none !important;
            counter-reset: item !important;

            li {
                box-sizing: border-box;
                padding-left: 2rem;
                counter-increment: item !important;

                margin-bottom: 1rem;
                position: relative;

                &::before {
                    margin-right: 10px;
                    content: counter(item);
                    background: $blue;
                    border-radius: 100%;
                    color: white;
                    width: 1.25em;
                    height: 1.25em;
                    text-align: center;
                    display: inline-block;

                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}