@import "../variables";


.home-supsection{
    display: flex;
    flex-flow: nowrap column;

    .access-message .section{
        padding-top: 4rem;
        padding-bottom: 4rem;

        background-color: $light-white;
    }
    .services-menu{
        background-color: $blue-gray;

        .home-submenu-access{
            display: flex;
            flex-flow: nowrap column;
            align-items: center;
            justify-content: center;

            gap: .75rem;

            cursor: pointer;

            *{
                display: block;
                width: 100%;
                text-align: center;

                color: white;
            }
            p{
                font-weight: 300;
                font-size: .9rem;
            }
            img{
                object-fit: contain;
                height: 6rem;
                font-size: 3rem;
                padding-bottom: 0rem;
                border-bottom: 2px solid $blue;
            }
        }
    }
    .img-phone-home{
        max-height: 60vh;
        object-fit: cover;
        object-position: 50% 40%;
    }
}

.card-all-sections{
    &>div{
        background-color: $light-white;

        box-sizing: border-box;
        width: 100%;
        height: 100%;

        &>span{
            text-align: center;
            width: 100%;
            display: block;
            margin-bottom: 0;

            word-break: break-word;
        }
    }
}
/*
.cardsmanager-home-allsections{
    @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(3, 1fr) !important;
    }
    @media screen and (max-width: $mini-mobile) {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}*/