@import "../variables";

.cercoverde-page {
    .title-component{
        &>div{
            background-color: $green-light;
        }
    }
    .bi-arrow-right{
        background-color: $green-light !important;
    }
    .cercoverde-content {
        display: flex;
        flex-flow: nowrap column;
        align-items: stretch;
        justify-content: center;

        &>section:nth-child(even){
            background-color: rgba($color: $gray, $alpha: 0.05);
        }
    }

    .categories-swiper {
        padding-bottom: 3rem;

        .swiper-wrapper{
            flex-direction: row;
        }
        .slide-categorie {
            display: flex;
            flex-flow: nowrap column;
            align-items: center;
            justify-content: flex-start;

            cursor: pointer;

            background-color: rgba($color: $gray, $alpha: 0.05);

            border-radius: $radius;

            position: relative;

            &>img {
                display: block;

                width: 3rem;
                height: 3rem;

                max-width: 3rem;
                max-height: 3rem;

                min-width: 3rem;
                min-height: 3rem;

                border-radius: 3rem;

                margin-top: 1rem;
            }

            &>span {
                display: block;
                width: 100%;
                text-align: center;

                color: black;
                font-size: .9rem;
                font-weight: 300;

                padding: 1rem 1rem;

                box-sizing: border-box;
            }
            &>small.badge{
                position: absolute;
                bottom: .5rem;
                right: .5rem;
                background-color: $green-light;
                color: white;
                
                font-size: .6rem;
                font-style: italic;
                font-weight: 200;
                padding: 0rem .25rem;
                border-radius: $radius;

                &[class^="len:0"]{
                    display: none;
                }
            }

            &.active {
                background-color: $green-light !important;

                &>span{
                    color: white;
                }
                &>small.badge{
                    background-color: white;
                    color: $green-light;
                }
            }

            &:hover {
                background-color: $green-light;
                box-shadow: $dark-shadow;

                &>span {
                    color: white;
                }
                &>small.badge{
                    background-color: white;
                    color: $green-light;
                }
            }
        }

        .swiper-pagination {
            .swiper-pagination-bullet-active {
                background-color: $green-light;
            }
        }
    }

    .search-section{
        .form-content.expanded{
            padding: 0;

            max-width: 100%;
            width: 100%;
            min-height: unset !important;

            display: grid;
            grid-template-columns: minmax(0, 8fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 5fr) ;
            gap: 1rem;

            .basic-multi-select{
                width: 100%;
            }

            .form-input{
                height: 100%;
                margin: 0;
                .form-input-element{
                    height: 100%;

                    input{
                        height: 100% !important;
                        max-height: 100% !important;
                    }
                }
                &>label{
                    white-space: nowrap;
                }

                //
                .select__value-container{
                    flex-wrap: nowrap !important;
                    overflow: auto !important;

                    .select__multi-value{
                        min-width: unset !important;
                    }
                }

                &#{"1"} input, &#{"2"} input{
                    /*                    
                    text-overflow: ellipsis;
                    padding-right: .75rem;
                    */
                    padding-right: 1rem;
                }
                &#{"1"} .form-input-element, &#{"2"} .form-input-element{
                    position: relative;
                }
                &#{"1"} .form-input-element::after, &#{"2"} .form-input-element::after{
                    content: "Bs";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 3px;

                    font-size: .6rem;
                    font-weight: 500;
                    font-style: italic;
                    color: rgba($color: black, $alpha: .8);
                }
                &#{"1"} .form-input-element:hover::after, &#{"2"} .form-input-element:hover::after,
                &#{"1"} .form-input-element:focus::after, &#{"2"} .form-input-element:focus::after,
                &#{"1"} .form-input-element:focus-visible::after, &#{"2"} .form-input-element:focus-visible::after,
                &#{"1"} .form-input-element:focus-within::after, &#{"2"} .form-input-element:focus-within::after{
                    content: "";
                }
            }

            @media screen and (max-width: 700px) {
                grid-template-columns: minmax(0, 6fr) minmax(0, 1fr) minmax(0, 1fr) ;

                .form-input{
                    height: 60px;

                    &.multiselect{
                        grid-column-start: 1;
                        grid-column-end: -1;
                    }
                }
            }
            @media screen and (max-width: 450px) {
                grid-template-columns: minmax(0, 3fr) minmax(0, 1fr) minmax(0, 1fr) ;
            }
        }
    }
}