@import "../variables";

.plan-container{
    margin: 0 auto;
    .plan-unique{
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        gap: 0rem;

        &>img{
            width: 100%;
            object-fit: cover;
            border-radius: $radius $radius 0 0;
        }
        &>div{
            width: 100%;
        }
    }
}
